import { Component, OnInit } from '@angular/core';
import { Respuesta } from 'src/app/interfaces/respuesta.interface';
import { Usuario } from 'src/app/interfaces/usuario.interface';
import { HorasService } from 'src/app/services/horas.service';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

  public usuarioActivo: Usuario = environment.usuario;

  public hoy: string = this.misFuncionesService.hoy();
  public horasUsuario: number = 0;
  public horasProyecto: number = 0;

  constructor(
            private horasService: HorasService,
            private misFuncionesService: MisFuncionesService,
  ) { }

  ngOnInit(): void {
    this.cargarHorasUsuario();
  }

  async cargarHorasUsuario(){
    const resp: Respuesta = await this.horasService.getHorasFecha(this.usuarioActivo.id!, this.hoy).toPromise();
    if(resp.status != 200) {
      // this.mensaje.textoAlert = resp.detalle;
      // this.mensaje.alert = true;
    }else {
      this.horasUsuario = Number(resp.detalle);
    }  
  }

}
