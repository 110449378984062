import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Hora } from '../interfaces/hora.interface';

const base_url = `${ environment.base_url }/Horas`;


@Injectable({
  providedIn: 'root'
})
export class HorasService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient) { }

  getComboCargos(orden:string = 'descripcion', desc:number = 0){
    const url = `${ base_url }/getAllCargos?order=${orden}&desc=${desc}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevaHora(data:Hora){
    const url = `${ base_url }/postHora`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarHora(data:Hora){
    const url = `${ base_url }/putHora/${data.id}`;
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarHora(id:number){
    const url = `${ base_url }/borrarHora/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getHoraId(id:number){
    const url = `${ base_url }/getHoraId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getHorasProyecto(idProyecto:number){
    const url = `${ base_url }/getHorasProyecto/${idProyecto}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getHorasFecha(idUsuario:number, fecha: string){
    const url = `${ base_url }/getHorasFecha/${idUsuario}/${fecha}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getRentabilidadProyectos(desdeProyecto: string, hastaProyecto: string, desdeEstado: number, hastaEstado: number, idCliente: number){
    const url = `${ base_url }/getRentabilidadProyectos?deproh=${desdeProyecto}&haproh=${hastaProyecto}&dest=${desdeEstado}&hast=${hastaEstado}&ccli=${idCliente}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getInformeProyectos(desdeProyecto: string, hastaProyecto: string, desdeEstado: number, hastaEstado: number, idCliente: number = 0){
    const url = `${ base_url }/getInformeProyectos?deproh=${desdeProyecto}&haproh=${hastaProyecto}&dest=${desdeEstado}&hast=${hastaEstado}&ccli=${idCliente}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getControlHoras(idUsuario: number, desdeFecha: Date, hastaFecha: Date){
    const url = `${ base_url }/getControlHoras/${idUsuario}/${desdeFecha}/${hastaFecha}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getHorasEmpleados(desdeFecha: Date, hastaFecha: Date,  desdeEmpleado = 0, hastaEmpleado = 9999 ){
    const url = `${ base_url }/getHorasEmpleados/${desdeFecha}/${hastaFecha}/${desdeEmpleado}/${hastaEmpleado}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAllHoras(desdeCliente: string, hastaCliente: string, desdeProyecto: string, hastaProyecto: string, desdeFecha: Date, hastaFecha: Date, pagina:number =1, rows:number = 15, orden:string = 'fecha', desc:number = 0, desdeIdUsuario = 0, hastaIdUsuario = 999999, idCliente = 0){
    const url = `${ base_url }/getAllHoras?page=${pagina}&rows=${rows}&order=${orden}&desc=${desc}&deus=${desdeIdUsuario}&haus=${hastaIdUsuario}&defec=${desdeFecha}&hafec=${hastaFecha
    }&depro=${desdeProyecto}&hapro=${hastaProyecto}&decli=${desdeCliente}&hacli=${hastaCliente}&idcli=${idCliente}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAll(desdeCliente: string, hastaCliente: string, desdeProyecto: string, hastaProyecto: string, desdeFecha: Date, hastaFecha: Date, pagina:number =1, rows:number = 15, orden:string = 'fecha', desc:number = 0, desdeIdUsuario = 0, hastaIdUsuario = 999999, idCliente = 0){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&order=${orden}&desc=${desc}&deus=${desdeIdUsuario}&haus=${hastaIdUsuario}&defec=${desdeFecha}&hafec=${hastaFecha
    }&depro=${desdeProyecto}&hapro=${hastaProyecto}&decli=${desdeCliente}&hacli=${hastaCliente}&idcli=${idCliente}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getDetalleIdProyecto(idCliente: number, idProyecto: number, desdeFecha: Date, hastaFecha: Date, pagina:number =1, rows:number = 15, orden:string = 'fecha', desc:number = 0){
    const url = `${ base_url }/getDetalleIdProyecto?page=${pagina}&rows=${rows}&order=${orden}&desc=${desc}&defec=${desdeFecha}&hafec=${hastaFecha
    }&proid=${idProyecto}&idcli=${idCliente}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  transferirHoras(data:Hora[], idProyecto: number){
   const url = `${ base_url }/transferirHoras/${idProyecto}`;
   
   return this.http.post(url, data, {headers: this.headers})
                   .pipe(
                     map(
                       (resp:any)=>{
                         const respuesta = {status:resp.status, detalle:resp.detalle};
                         return respuesta;
                       }
                     )
                   )
                   ;
 }
}
