/* -------------------------------------------------------------------------- */
/*                               MODULOS ANGULAR                              */
/* -------------------------------------------------------------------------- */

import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from "@angular/common/http";



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* -------------------------------------------------------------------------- */
/*                                 COMPONENTES                                */
/* -------------------------------------------------------------------------- */
// import { DomseguroPipe } from './pipes/domseguro.pipe';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { registerLocaleData } from '@angular/common';
import localEs from '@angular/common/locales/es';
import { LoginComponent } from './pages/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { PasswordComponent } from './pages/password/password.component';


registerLocaleData(localEs);

@NgModule({
  declarations: [
    AppComponent,
    // DomseguroPipe,
    
    NavbarComponent,
    LoginComponent,
    PasswordComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,

  ],
  exports: [
    
  
   
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'es-ES' // 'de-DE' for Germany, 'fr-FR' for France ...
  },
],
  bootstrap: [AppComponent]
})
export class AppModule { }
