import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { InformesGuard } from './guards/informes.guard';
import { MantenimientosGuard } from './guards/mantenimientos.guard';
import { LoginComponent } from './pages/login/login.component';
import { PasswordComponent } from './pages/password/password.component';

const routes: Routes = [
  {
    path: 'utilidades', 
    loadChildren: () => import('./pages/utilidades/utilidades.module').then(m => m.UtilidadesModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reservado', 
    loadChildren: () => import('./pages/reservado/reservado.module').then(m => m.ReservadoModule), canLoad: [AuthGuard], canActivate: [AuthGuard, MantenimientosGuard]
  },
  {
    path: 'mantenimientos', 
    loadChildren: () => import('./pages/mantenimientos/mantenimientos.module').then(m => m.MantenimientosModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'horas', 
    loadChildren: () => import('./pages/horas/horas.module').then(m => m.HorasModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/almacen', 
    loadChildren: () => import('./reports/almacen/almacen.module').then(m => m.RAlmacenModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/informes', 
    loadChildren: () => import('./reports/informes/informes.module').then(m => m.RInformesModule), canLoad: [AuthGuard], canActivate: [AuthGuard, InformesGuard]
  },
  {
    path: 'reports/ventas', 
    loadChildren: () => import('./reports/ventas/ventas.module').then(m => m.RVentasModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/utilidades', 
    loadChildren: () => import('./reports/utilidades/utilidades.module').then(m => m.UtilidadesModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'informes', 
    loadChildren: () => import('./pages/informes/informes.module').then(m => m.InformesModule), canLoad: [AuthGuard], canActivate: [AuthGuard, InformesGuard]
  },
  
  // { path: "**", pathMatch: "full", redirectTo: "utilidades" },
  { path: 'login', component: LoginComponent },
  { path: 'password/:id', component: PasswordComponent },

  { path: "**", pathMatch: "full", redirectTo: "login" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
