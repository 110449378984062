<div [ngClass]="claseContenedor()">
    
    <form autocomplete="off" [formGroup]=formulario (ngSubmit)="validarFormulario()">
        <div class="row mb-3">
            <div class="col-lg-8">
                <h2 [ngClass]="claseH2()" class="text-center">Cambio de Contraseña</h2>
            </div>
        </div>
        
        <div class="row mt-2">
            <h3 class="col-lg-12 text-center"><strong>{{usuarioActivo.nombreCompleto}}</strong></h3>
        </div>

        <br>
        <br>
        <br>

        <h2 [ngClass]="claseH2()" class="text-center">Datos de Acceso</h2>
        <br>

        <div class="form-group row mt-2">
            <label class="col-lg-4 col-md-5 col-form-label text-end">Alias</label>
            <div class="col-lg-3 col-md-6">
                <input mTab class="form-control" id=nombre type="text" placeholder="Alias" formControlName="nombre" readonly>
                <small *ngIf="campoNoValido('nombre')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
            </div>
        </div>
        <div class="form-group row mt-2">
            <label class="col-lg-4 col-md-5 col-form-label text-end">Cambiar Password</label>
            <div class="col-lg-3 col-md-6">
                <input mTab (focusout) = "validarSiPasswordEnUso()" class="form-control" id=password type="password" placeholder="Password" formControlName="password" #password>
                <small *ngIf="campoNoValido('password')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
            </div>
            <!-- <label class="col-lg-2 col-md-4 col-form-label text-end">Cambiar Pin</label>
            <div class="col-lg-2 col-md-4">
                <input mTab class="form-control" id=pin type="password" placeholder="Pin" formControlName="pin">
                <small *ngIf="campoNoValido('pin')" class="form-text text-danger">Debe contener 4 números</small>
            </div> -->
        </div>

        <div class="form-group row mt-2">
            <label class="col-lg-4 col-md-5 col-form-label text-end"> Confirmación</label>
            <div class="col-lg-3 col-md-6">
                <input mTab class="form-control" id=rpassword type="password" placeholder="Password" formControlName="rpassword">
                <small *ngIf="campoNoValido('rpassword')" class="form-text text-danger">El Password no coincide</small>
            </div>
            <!-- <label class="col-lg-2 col-md-4 col-form-label text-end">Confirmación</label>
            <div class="col-lg-2 col-md-4">
                <input mTab class="form-control" id=rpin type="password" placeholder="Pin" formControlName="rpin">
                <small *ngIf="campoNoValido('rpin')" class="form-text text-danger">El Pin no coincide</small>
            </div> -->
        </div>






        <div class="row mt-4">
            <app-botonera class="botonera" [botones]="['grabar']"></app-botonera>
        </div>
    </form>


</div>
<app-spinner *ngIf="activarSpinner"></app-spinner>
<app-mensaje [mensaje]=mensaje (botonesClick)="controlBotones($event)"></app-mensaje>
