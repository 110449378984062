<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-primary d-print-none encima"> -->
<nav class="navbar navbar-expand-lg navbar-dark fondoNaranja d-print-none encima">
    <div class="container-fluid">
        <a class="navbar-brand clickable blanco">aHora
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch-fill" viewBox="0 0 16 16">
                <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584.531.531 0 0 0 .013-.012l.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354a.717.717 0 0 0-.012.012A6.973 6.973 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1h-3zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0z"/>
            </svg>
        </a>
        <button class="navbar-toggler blanco" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon blanco"></span>
        </button>
        <div class="collapse navbar-collapse blanco" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                <li *ngIf="mostrarMantenimientos" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarMantenimientos" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Mantenimientos</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuMantenimientos; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta'>{{item.texto}}</a>
                            <hr *ngIf="dividersMantenimientos.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarBar" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Horas</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuHoras; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta'>{{item.texto}}</a>
                            <hr *ngIf="dividersHoras.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>


                <li *ngIf="mostrarInformes" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarInformes" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Informes</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuInformes; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta'>{{item.texto}}</a>
                            <hr *ngIf="dividersInformes.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle clickable" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="true" style="color:white;">Utilidades</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngFor="let item of menuUtilidades; let i = index;">
                            <a class="dropdown-item" [routerLink]='item.ruta'>{{item.texto}}</a>
                            <hr *ngIf="dividersUtilidades.includes(i);" class="dropdown-divider">
                        </li>
                    </ul>
                </li>

            </ul>
        </div>
    </div>
</nav>