import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Articulo } from 'src/app/interfaces/articulo.interface';
import { Buscador } from 'src/app/interfaces/buscador.interface';
import { Cliente } from 'src/app/interfaces/cliente.interface';
import { Familia } from 'src/app/interfaces/familia.interface';
import { Parcela } from 'src/app/interfaces/parcela.interface';
import { Proveedor } from 'src/app/interfaces/proveedor.interface';
import { ReservaParcela } from 'src/app/interfaces/reservaParcela.interface';
import { SubFamilia } from 'src/app/interfaces/subFamilia.interface';
import { Usuario } from 'src/app/interfaces/usuario.interface';
import { Vehiculo } from 'src/app/interfaces/vehiculo.interface';
import { ArticulosService } from 'src/app/services/articulos.service';
import { ClientesService } from 'src/app/services/clientes.service';
import { FamiliasService } from 'src/app/services/familias.service';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { ParcelasService } from 'src/app/services/parcelas.service';
import { ProveedoresService } from 'src/app/services/proveedores.service';
import { ReservasParcelaService } from 'src/app/services/reservas-parcela.service';
import { SubFamiliasService } from 'src/app/services/subfamilias.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { VehiculosService } from 'src/app/services/vehiculos.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent implements OnInit, AfterViewInit {

  @Input() tablaBusqueda:string = 'clientes';
  @Input() devolverId:boolean = false;
  @Output() onCancel:EventEmitter<number> = new EventEmitter();
  @Output() onId:EventEmitter<number> = new EventEmitter();
  @ViewChild('entrada') domEntrada!:ElementRef;

  // VARIABLES PAGINADOR
  // public paginas:number[] = [];
  public totalPaginas:number = 1;
  public paginaActiva : number = 1;
  
  // VARIABLES TABLA 
  public articulos: Articulo[] = [];
  public clientes: Cliente[] = [];
  public familias: Familia[] = [];
  public parcelas: Parcela[] = [];
  public proveedores: Proveedor[] = [];
  public reservasParcela: ReservaParcela[] = [];
  public subFamilias: SubFamilia[] = [];
  public usuarios: Usuario[] = [];
  public vehiculos: Vehiculo[] = [];
  public columns: string[] = ['Código','Nombre','Responsable'];
  public maxRows: number = 10;
  public rows: Buscador[] = [];
  public titulo:string = 'Buscador de Clientes';
  public valoraBuscar:string = '';

  public formulario: FormGroup = this.fb.group({
    entrada:     [''],
  });

  debouncer: Subject<string> = new Subject();

  constructor(private fb: FormBuilder,
              private router: Router,
              private articulosService: ArticulosService,
              private clientesService: ClientesService,
              private familiasService: FamiliasService,
              private misFuncionesService: MisFuncionesService,
              private parcelasService: ParcelasService,
              private proveedoresService: ProveedoresService,
              private reservasParcelaService: ReservasParcelaService,
              private subFamiliasService: SubFamiliasService,
              private usuariosService: UsuariosService,
              private vehiculosService: VehiculosService,
    ) { }

  ngAfterViewInit(): void {
    this.domEntrada.nativeElement.focus();
  }

  ngOnInit(): void {

    switch (this.tablaBusqueda) {
      case 'articulos':
          this.columns = ['Código','Descripción','Desc. Corta'];
          this.titulo = "Buscador de Artículos";
        break;
      case 'clientes':
          this.columns = ['Código','Nombre','Responsable'];
        break;
      case 'familias':
          this.columns = ['Código','Descripción','Desc. Corta'];
          this.titulo = "Buscador de Familias";
        break;
      case 'parcelas':
          this.columns = ['Código','Descripción','Tipo'];
          this.titulo = "Buscador de Parcelas";
        break;
      case 'proveedores':
          this.columns = ['Código','Nombre Fiscal','Nombre Comercial', 'Cif'];
          this.titulo = "Buscador de Proveedores";
        break;
      case 'reservasParcela':
          this.columns = ['Nombre','Teléfono','Fecha Entrada', 'Parcela'];
          this.titulo = "Buscador de Reservas";
        break;
      case 'subFamilias':
          this.columns = ['Código','Descripción','Desc. Corta'];
          this.titulo = "Buscador de SubFamilias";
        break;
      case 'usuarios':
          this.columns = ['Nombre','Alias', 'Cargo'];
          this.titulo = "Buscador de Usuarios";
        break;
      case 'vehiculos':
          this.columns = ['Matrícula','Modelo', 'Nombre Fiscal'];
          this.titulo = "Buscador de Vehículos";
        break;
    
      default:
        break;
    }
    this.debouncer
      .pipe(debounceTime(300))
      .subscribe(valor=>{
      // console.log('devouncer: ', valor);
      this.valoraBuscar = valor;
      this.totalPaginas = 1;
      this.paginaActiva = 1;
      this.consultar();
    });

    this.consultar();
    
  }

  cancelar() {
    this.onCancel.emit(0);
  }

  rowClick(indice:number){
    let ruta:string = '';
    let id: number = 0;
    this.cancelar();
    switch (this.tablaBusqueda) {
      case 'articulos':
        id = this.articulos[indice].id!;
        ruta = `/mantenimientos/manArticulos/${id}`;
        break;
      case 'clientes':
        id = this.clientes[indice].id!;
        ruta = `/mantenimientos/manClientes/${id}`;
        break;
      case 'familias':
        id = this.familias[indice].id!;
        ruta = `/mantenimientos/manFamilias/${id}`;
        break;
      case 'parcelas':
        id = this.parcelas[indice].id!;
        ruta = `/camping/manParcelas/${id}`;
        break;
      case 'proveedores':
        id = this.proveedores[indice].id!;
        ruta = `/mantenimientos/manProveedores/${id}`;
        break;
      case 'reservasParcela':
        id = this.reservasParcela[indice].idParcela!;
        ruta = `/camping/reservaParcela/${id}`;
        break;
      case 'subFamilias':
        id = this.subFamilias[indice].id!;
        ruta = `/mantenimientos/manSubFamilias/${id}`;
        break;
      case 'usuarios':
        id = this.usuarios[indice].id!;
        ruta = `/reservado/manUsuarios/${id}`;
        break;
      case 'vehiculos':
        id = this.vehiculos[indice].id!;
        ruta = `/mantenimientos/manVehiculos/${id}`;
        break;
    
      default:
        break;
    }
    if (this.devolverId){
      this.onId.emit(id);
    }else{
      // return this.router.navigateByUrl(ruta);
      this.router.navigateByUrl(ruta);
    }
  }

  teclaPresionada(event: any){
    // const valor = event.target.value;
    const valor = this.misFuncionesService.eliminarBarra(event.target.value);
    this.debouncer.next(valor);

  }

  // configurarPaginador(totalPaginas:number){
  //   let i = 0;
  //   this.paginas = [];
  //   while (i < totalPaginas) {
  //     this.paginas.push(i + 1);
  //     i++;
  //   }
  // }

  consultar(pagina:number=1){
    // console.log('Evento: ', pagina);
    
    
    this.paginaActiva = pagina;
    // console.log('Página Activa Buscador: ', this.paginaActiva);
     switch (this.tablaBusqueda) {
        case 'articulos':
          this.buscarArticulo(this.valoraBuscar);
          break;
        case 'clientes':
          this.buscarCliente(this.valoraBuscar);
          break;
        case 'familias':
          this.buscarFamilia(this.valoraBuscar);
          break;
        case 'parcelas':
          this.buscarParcela(this.valoraBuscar);
          break;
        case 'proveedores':
          this.buscarProveedor(this.valoraBuscar);
          break;
        case 'reservasParcela':
          this.buscarReservasParcela(this.valoraBuscar);
          break;
        case 'subFamilias':
          this.buscarSubFamilia(this.valoraBuscar);
          break;
        case 'vehiculos':
          this.buscarVehiculo(this.valoraBuscar);
          break;
        case 'usuarios':
          this.buscarUsuario(this.valoraBuscar);
          break;
     
       default:
         break;
     }
  }


  /* -------------------------------------------------------------------------- */
  /*                                  BUSQUEDAS                                 */
  /* -------------------------------------------------------------------------- */

  buscarCliente(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.clientesService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.clientes = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.clientes.forEach(
                                      cliente =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = cliente.codigo;
                                        row.campo2 = cliente.nombre;
                                        row.campo3 = cliente.nombreResponsable;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.clientesService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.clientes = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.clientes.forEach(
                                      cliente =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = cliente.codigo;
                                        row.campo2 = cliente.nombre;
                                        row.campo3 = cliente.nombreResponsable;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();

  }

  buscarFamilia(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.familiasService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.familias = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.familias.forEach(
                                      familia =>{
                                        let row: Buscador = {};
                                        row.campo1 = familia.codigo;
                                        row.campo2 = familia.descripcion;
                                        row.campo3 = familia.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.familiasService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.familias = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.familias.forEach(
                                      familia =>{
                                        let row: Buscador = {};
                                        row.campo1 = familia.codigo;
                                        row.campo2 = familia.descripcion;
                                        row.campo3 = familia.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarParcela(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.parcelasService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.parcelas = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.parcelas.forEach(
                                      parcela =>{
                                        let row: Buscador = {};
                                        row.campo1 = parcela.codigo;
                                        row.campo2 = parcela.descripcion;
                                        row.campo3 = parcela.tipo;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.parcelasService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.parcelas = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.parcelas.forEach(
                                      parcela =>{
                                        let row: Buscador = {};
                                        row.campo1 = parcela.codigo;
                                        row.campo2 = parcela.descripcion;
                                        row.campo3 = parcela.tipo;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarProveedor(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            this.proveedoresService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.proveedores = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.proveedores.forEach(
                                      proveedor =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = proveedor.codigo;
                                        row.campo2 = proveedor.nombreFiscal;
                                        row.campo3 = proveedor.nombreComercial;
                                        row.campo4 = proveedor.cif;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }else{
          this.proveedoresService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.proveedores = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.proveedores.forEach(
                                      proveedor =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = proveedor.codigo;
                                        row.campo2 = proveedor.nombreFiscal;
                                        row.campo3 = proveedor.nombreComercial;
                                        row.campo4 = proveedor.cif;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();

  }

  buscarReservasParcela(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            this.reservasParcelaService.busqueda(valor, this.paginaActiva, this.maxRows, 'nombre')
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.reservasParcela = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.reservasParcela.forEach(
                                      reserva =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = reserva.nombre;
                                        row.campo2 = reserva.telefono;
                                        row.campo3 = this.misFuncionesService.formatearFecha(this.misFuncionesService.fechaaString(new Date(reserva.fechaEntrada!)));
                                        row.campo4 = reserva.codigoParcela;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }else{
          this.reservasParcelaService.getAll(this.paginaActiva, this.maxRows, 'nombre',0, this.misFuncionesService.principioDeLosTiempos(), this.misFuncionesService.finDeLosTiempos())
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.reservasParcela = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.reservasParcela.forEach(
                                      reserva =>{
                                        let row: Buscador = {};
        
                                        row.campo1 = reserva.nombre;
                                        row.campo2 = reserva.telefono;
                                        row.campo3 = this.misFuncionesService.formatearFecha(this.misFuncionesService.fechaaString(new Date(reserva.fechaEntrada!)));
                                        row.campo4 = reserva.codigoParcela;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();

  }

  buscarSubFamilia(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            // console.log('Busco Cliente: ',valor);
            this.subFamiliasService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.subFamilias = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;
                                    

                                    this.subFamilias.forEach(
                                      subFamilia =>{
                                        let row: Buscador = {};
                                        row.campo1 = subFamilia.codigo;
                                        row.campo2 = subFamilia.descripcion;
                                        row.campo3 = subFamilia.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.subFamiliasService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.subFamilias = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.subFamilias.forEach(
                                      subFamilia =>{
                                        let row: Buscador = {};
                                        row.campo1 = subFamilia.codigo;
                                        row.campo2 = subFamilia.descripcion;
                                        row.campo3 = subFamilia.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarArticulo(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            if (this.paginaActiva > this.totalPaginas){this.paginaActiva = this.totalPaginas;}
            this.articulosService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.articulos = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.articulos.forEach(
                                      articulo =>{
                                        let row: Buscador = {};
                                        row.campo1 = articulo.codigo;
                                        row.campo2 = articulo.descripcion;
                                        row.campo3 = articulo.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.articulosService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,'', environment.marcaFinal,false,'codigo',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.articulos = resp.detalle;
                                    // this.configurarPaginador(resp.totalPaginas);
                                    this.totalPaginas = resp.totalPaginas;

                                    this.articulos.forEach(
                                      articulo =>{
                                        let row: Buscador = {};
                                        row.campo1 = articulo.codigo;
                                        row.campo2 = articulo.descripcion;
                                        row.campo3 = articulo.descripcionCorta;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarUsuario(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            if (this.paginaActiva > this.totalPaginas){this.paginaActiva = this.totalPaginas;}
            this.usuariosService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.usuarios = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.usuarios.forEach(
                                      usuario =>{
                                        let row: Buscador = {};
                                        row.campo1 = usuario.nombreCompleto;
                                        row.campo2 = usuario.nombre;
                                        row.campo3 = usuario.descripcionCargo;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.usuariosService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'nombre',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.usuarios = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.usuarios.forEach(
                                      usuario =>{
                                        let row: Buscador = {};
                                        row.campo1 = usuario.nombreCompleto;
                                        row.campo2 = usuario.nombre;
                                        row.campo3 = usuario.descripcionCargo;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }

  buscarVehiculo(valor:string){
    this.rows = [];
        if (valor.length > 0) {
            if (this.paginaActiva > this.totalPaginas){this.paginaActiva = this.totalPaginas;}
            this.vehiculosService.busqueda(valor, this.paginaActiva, this.maxRows)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.vehiculos = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.vehiculos.forEach(
                                      vehiculo =>{
                                        let row: Buscador = {};
                                        row.campo1 = vehiculo.codigo;
                                        row.campo2 = vehiculo.modelo;
                                        row.campo3 = vehiculo.nombreFiscal;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
                            // console.log(this.rows);
                            
        }else{
          this.vehiculosService.getAll(this.paginaActiva, this.maxRows, '', environment.marcaFinal,'', environment.marcaFinal,'matricula',0)
                            .subscribe(
                              resp => {
                                if (resp.status == 200) {
                                    this.vehiculos = resp.detalle;
                                    this.totalPaginas = resp.totalPaginas;

                                    this.vehiculos.forEach(
                                      vehiculo =>{
                                        let row: Buscador = {};
                                        row.campo1 = vehiculo.codigo;
                                        row.campo2 = vehiculo.modelo;
                                        row.campo3 = vehiculo.nombreFiscal;
                                        this.rows.push(row);
                                      }
                                    )
                                }
                              }
                            );
        }
   this.domEntrada?.nativeElement.focus();
  
  }
}
