import { AfterViewInit, Component, OnInit, enableProdMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Respuesta } from './interfaces/respuesta.interface';
import { MisFuncionesService } from './services/mis-funciones.service';
import { UsuariosService } from './services/usuarios.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit{
  public url:string = '';
  public usuario: string = localStorage.getItem('tp-Usuario') || '';
  public barraVisible:boolean = false;
  public ocultarBarra:boolean = false;
  // public barraMenu = document.getElementById('navBar')!;
  public barraMenu: HTMLElement | undefined ;


  constructor(
    private misFuncionesService: MisFuncionesService,
    private usuariosService: UsuariosService,
  ) { }
  ngAfterViewInit(): void {
    this.barraMenu = document.getElementById('navBar')!;

  }

  ngOnInit(): void {

    

    // El archivo assets configuracion.txt solo debe de usarse para pruebas, ya que si se modifica y no coincide con environment no funcionará el refresco de pantalla ni el enrutamiento porque el auth no actualiza.
    
    this.leerConfiguracion();
    this.guardarUsuarioActivo();
    
  }
  title = 'aHora';
  
  leerConfiguracion(){
    // const conf = configuracion;
    // environment.base_url = conf.base_url;
    this.misFuncionesService.leerConfiguracion().subscribe(
      resp=>{
        const config:any = resp;
        // console.log(config);
        
        this.url = config.base_url;
        environment.base_url = config.base_url;
        if (config.activarVoz === 'true') {environment.activarVoz = true;}
        if (config.codigoClienteAutomatico === 'true') {environment.codigoClienteAutomatico = true;}
        if (config.gmt) {environment.gmt = config.gmt;}
        if (config.imprimirAlGrabar === 'true') {environment.imprimirAlGrabar = config.imprimirAlGrabar;}
      }
    );
  }

  navBarVisible(){
    if (this.barraMenu){
        if (this.barraVisible){
          this.barraMenu.style.setProperty("--posicionFinal", "3px");
          this.barraMenu.style.setProperty("--posicionInicial", "-5px");
          this.barraMenu.style.setProperty("--tiempo", "0.5s");
          if(!this.barraFija) {return 'movimientoVertical';}
          return '';
        }else{
           if(!this.barraFija) {return 'oculto'};
           return ''; 
        }
    }else{
      if(!this.barraFija) {return 'oculto'}; 
      return '';
    }
  }

  ocultarNavBar(){
    this.ocultarBarra = true;
    setTimeout(() => {
      if (this.ocultarBarra){
        this.barraVisible = false;
      }
    }, 1000);
  }

  mostrarNavBar(){
    this.barraVisible = true;
  }

  async guardarUsuarioActivo(){
    const usuario = localStorage.getItem('tp-Usuario');
    const resp: Respuesta = await this.usuariosService.getUsuarioCodigo(usuario!).toPromise()
    if (resp.status == 200){
      environment.usuario = resp.detalle;
    }
 }

 

  get barraFija(){
    if (localStorage.getItem('fijarBarra') == 'true'){
      return true;
    }
    return false;
  }
}
