import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Cargo } from 'src/app/interfaces/cargo.interface';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { Respuesta } from 'src/app/interfaces/respuesta.interface';
import { Usuario } from 'src/app/interfaces/usuario.interface';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { TabDirective } from 'src/app/shared/directives/tab.directive';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
     @ViewChild('password') domPassword!: ElementRef;
     // NECESARIO PARA ACTIVAR TABULADOR
     @ViewChildren(TabDirective) inputs!: QueryList<TabDirective>;
   
     // VARIABLES DE COMPONENTES  
      public activarSpinner: boolean = true;
      public mensaje:Mensaje={};
      public activarBuscador: boolean = false;
      public activarGaleria:boolean = false;
      public cargoActivo: Cargo = {id: 0, descripcion: 'Ninguno'};
      public cargos: Cargo[] = [];
 
  
      // VARIABLES DEL FORMULARIO
      public idActivo:number = 0;
      public usuarioActivo:Usuario = {};
      public formulario: FormGroup= this.fb.group({
       nombre:              ['', [Validators.required,  Validators.minLength(4)] ],
       password:            ['', [Validators.minLength(4)] ],
        pin:                 ['', [Validators.minLength(4), Validators.pattern('[0-9]*') ]],
        rpassword:           ['', [Validators.minLength(4)] ],
        rpin:                ['', [Validators.minLength(4), Validators.pattern('[0-9]*')]],
      },{
        validators: [this.misFuncionesService.controlPasswords('password','rpassword'), this.misFuncionesService.controlPasswords('pin','rpin')]
      });
  
 
   constructor(
               private activatedRoute: ActivatedRoute,
               private fb: FormBuilder,
               private misFuncionesService: MisFuncionesService,
               private router: Router,
               private usuariosService: UsuariosService,
   ) { }
   ngOnInit() {

     this.activatedRoute.params
           .subscribe(({id})=>{
             if (id > 0){
               this.idActivo = id;
               this.cargarUsuario(id)
             }else{
               this.usuarioActivo = environment.usuario;
               if (this.usuarioActivo.id){
                  this.router.navigateByUrl(`password/${this.usuarioActivo.id}`);
                }else{
                 this.router.navigateByUrl(`login`);
               }
             }
           });
    
 
   }
 
    /* -------------------------------------------------------------------------- */
    /*                      NECESARIO PARA ACTIVAR TABULADOR                      */
    /* -------------------------------------------------------------------------- */
 
   ngAfterViewInit(): void {
     this.initTab();
     
     
   }
 
   initTab(){
     const controls=this.inputs.toArray();
     for (let i=0;i<controls.length-1;i++){
       controls[i].nextControl=controls[i+1].self;
     }
   }
 
   /* -------------------------------------------------------------------------- */
   /*                           NECESARIO PARA MENSAJE                           */
   /* -------------------------------------------------------------------------- */
 
   controlBotones(evento:number){
     this.mensaje.botones = false;
     this.mensaje.persistente = false;
     if (evento == 1){
       eval(this.mensaje.comandoAceptar!);
     }else{
       eval(this.mensaje.comandoCancelar!);
     }
   }
  
    /* -------------------------------------------------------------------------- */
   /*                                VALIDACIONES                                */
   /* -------------------------------------------------------------------------- */
 
   campoNoValido( campo: string){
     return (this.formulario.controls[campo].errors && this.formulario.controls[campo].touched)
   }
 
   validarFormulario(){
     
     if (this.formulario.valid ){
       this.controlPassword();
     }  else {
       this.mensaje.textoAlert = 'Errores en el formulario. Revise los campos.';
       this.mensaje.alert = true;
       return Object.values(this.formulario.controls).forEach(control => {
         if (control instanceof FormGroup) { 
           return Object.values(control.controls).forEach(control => {control.markAsTouched()})
         }else{
         control.markAsTouched();
       }});
     }
   }
 
   controlPassword(){
    
     if (this.formulario.get('password')?.value && this.formulario.get('password')?.value.length > 0 ){
         this.misFuncionesService.encriptar(`password=${this.formulario.get('password')?.value}`)
                                 .subscribe(
                                   resp => {
                                     if(resp.status != 200) {
                                       this.mensaje.textoAlert = resp.detalle;
                                       this.mensaje.alert = true;
                                     }else {
                                       this.mensaje.textoSuccess = resp.detalle;
                                       this.usuarioActivo.password = resp.detalle;
                                       this.controlPin();
                                     }  
                                   }
                                 );
     }else{
       this.controlPin();
     }
   }
 
   controlPin() {
     
     if (this.formulario.get('pin')?.value && this.formulario.get('pin')?.value.length > 0 ){
         this.misFuncionesService.encriptar(`password=${this.formulario.get('pin')?.value}`)
                                 .subscribe(
                                   resp => {
                                     if(resp.status != 200) {
                                       this.mensaje.textoAlert = resp.detalle;
                                       this.mensaje.alert = true;
                                     }else {
                                       this.mensaje.textoSuccess = resp.detalle;
                                       this.usuarioActivo.pin = resp.detalle;
                                       this.grabar();
                                     }  
                                   }
                                 );
     }else{
       this.grabar();
     }
 
   }

   async validarSiPasswordEnUso(){
    //Comprobamos que no esté intentando grabar la contraseña antigua. 
    const resp: Respuesta = await this.usuariosService.comprobarSiPasswordEnUso(this.usuarioActivo.id!, this.formulario.get('password')!.value).toPromise();
    if(resp.status != 200) {
      console.log(resp.detalle);
    }else {
      // console.log(resp.detalle);
      
      if (resp.detalle == true) {
        this.mensaje.textoAlert = 'No se puede utilizar la misma contraseña.';
        this.mensaje.alert = true;
        this.resetearFormulario();
      }
    }

   }
 
   
   /* -------------------------------------------------------------------------- */
   /*                           FUNCIONES DEL FORMULARIO                         */
   /* -------------------------------------------------------------------------- */
 
   cargarFormulario(){
     this.formulario.reset({
       nombre: this.usuarioActivo.nombre,
       nombreCompleto: this.usuarioActivo.nombreCompleto,
       nivel: this.usuarioActivo.nivel,
       precioHora: this.usuarioActivo.precioHora,
     });
   }
 
   resetearFormulario(){
     this.formulario.reset({
       nombre: this.usuarioActivo.nombre,
       password: '',
       pin: '',
       rpassword: '',
       rpin: '',
     });
     this.domPassword.nativeElement.focus();
    //  this.idActivo = 0;
    //  this.usuarioActivo = {};
    //  this.usuarioActivo.imagen = environment.noImage;
    //  this.cargoActivo = {id: 0, descripcion: 'Ninguno'};
   }
 
   cargarUsuario(id:number){
     this.activarSpinner = true;
     this.usuariosService.getUsuarioId(id)
                         .subscribe(
                           resp => {
                             if(resp.status != 200) {
                               this.mensaje.textoAlert = resp.detalle;
                               this.mensaje.alert = true;
                               this.activarSpinner = false;
                             }else {
                               this.usuarioActivo = resp.detalle;
                               this.cargarFormulario();
                               this.activarSpinner = false;
                             }  
                           }
                         );
   }
 
   grabar(){
 
     // FORMATEAMOS LOS DATOS    
  
    //  this.usuarioActivo.id = this.idActivo;
    //  this.usuarioActivo.nombre = this.formulario.get('nombre')!.value;
    //  this.usuarioActivo.nombreCompleto = this.formulario.get('nombreCompleto')!.value;
    //  // this.usuarioActivo.nivel = this.formulario.get('nivel')!.value;
    //  this.usuarioActivo.precioHora = this.formulario.get('precioHora')!.value;
    //  this.usuarioActivo.idCargo = this.cargoActivo.id;
    //  this.usuarioActivo.descripcionCargo = this.cargoActivo.descripcion;
     
     
       if(this.idActivo == 0){
         // CREAR NUEVO USUARIO
         
       }else{
         // ACTUALIZAR USUARIO 
         this.usuariosService.actualizar(this.usuarioActivo)
                             .subscribe(
                               resp => {
                                 if(resp.status != 200) {
                                   this.mensaje.textoAlert = resp.detalle;
                                   this.mensaje.alert = true;
                                 }else {
                                   this.mensaje.textoSuccess = resp.detalle;
                                   this.mensaje.success = true;
                                   this.iraLogin();
                                 }  
                                 // this.activarSpinner = false;
                               }
                             );
       }
     
     
   }

   iraLogin(){
    setTimeout(() => {
       localStorage.removeItem('tp-Token');
       localStorage.removeItem('tp-Usuario');
       this.router.navigateByUrl('login');
    }, 1500);

   }
 
 
   claseContenedor(){
     if (this.formulario.dirty) {return 'container fondoFormulario bordeContenedorRojo';}
     return 'container fondoFormulario bordeContenedor'
   }
 
   claseH2(){
     if (this.formulario.dirty) {return 'rojo';}
     return '';
   }
 
  

}
