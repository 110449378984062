import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-botonera',
  templateUrl: './botonera.component.html',
  styleUrls: ['./botonera.component.css']
})
export class BotoneraComponent implements OnInit {
  @Input() botones:string[] = [];

  @Output() borrarClick:EventEmitter<number> = new EventEmitter();
  @Output() buscarClick:EventEmitter<number> = new EventEmitter();
  @Output() cancelarClick:EventEmitter<number> = new EventEmitter();
  @Output() csvClick:EventEmitter<number> = new EventEmitter();
  @Output() descargarClick:EventEmitter<number> = new EventEmitter();
  @Output() facturarClick:EventEmitter<number> = new EventEmitter();
  @Output() grabarClick:EventEmitter<number> = new EventEmitter();
  @Output() imprimirClick:EventEmitter<number> = new EventEmitter();
  @Output() inicioClick:EventEmitter<number> = new EventEmitter();
  @Output() mailClick:EventEmitter<number> = new EventEmitter();
  @Output() nuevoClick:EventEmitter<number> = new EventEmitter();
  @Output() ticketClick:EventEmitter<number> = new EventEmitter();
  @Output() volverClick:EventEmitter<number> = new EventEmitter();
  @Output() whatsAppClick:EventEmitter<number> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

 

  borrar(){
    this.borrarClick.emit(0);
  }

  buscar(){
    this.buscarClick.emit(0);
  }

  cancelar(){
    this.cancelarClick.emit(0);
  }

  csv(){
    this.csvClick.emit(0);
  }

  descargar(){
    this.descargarClick.emit(0);
  }

  facturar(){
    this.facturarClick.emit(0);
  }

  grabar(){
    this.grabarClick.emit(0);
  }

  imprimir(){
    this.imprimirClick.emit(0);
  }

  inicio(){
    this.inicioClick.emit(0);
  }

  mail(){
    this.mailClick.emit(0);
  }

  nuevo(){
    this.nuevoClick.emit(0);
  }

  ticket(){
    this.ticketClick.emit(0);
  }

  volver(){
    this.volverClick.emit(0);
  }

  whatsApp(){
    this.whatsAppClick.emit(0);
  }

}
