<div class="usuario container  bordeContenedor">
    <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-2">
            <h5>{{hoy | date:'dd/MM/yyyy'}}</h5> 
        </div>
        <div class="col-lg-4">
            <h5 class="text-center">{{usuarioActivo.nombreCompleto}}</h5>
        </div>
        <div class="col-lg-3">
            <h5>Horas Justificadas: &nbsp; {{horasUsuario}}</h5>
        </div>
    </div>
</div>
